import { Controller } from "@hotwired/stimulus";
import { toPng } from "html-to-image";

export default class ElementDownloadController extends Controller<HTMLDivElement> {
  static targets = ["button", "element"];
  static values = { filename: String };

  declare buttonTarget: HTMLButtonElement;
  declare elementTarget: HTMLElement;
  declare filenameValue: string;

  connect(): void {
    this.buttonTarget.addEventListener("click", this.onButtonClick.bind(this));
  }

  onButtonClick(): void {
    this.downloadImage()
      .then(() => {
        console.log("File downloaded successfully");
      })
      .catch(error => {
        console.error("Error in onButtonClick:", error);
      });
  }

  async downloadImage(): Promise<void> {
    const dataUrl = await toPng(this.elementTarget, {
      cacheBust: true,
      backgroundColor: 'white'
    });
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = typeof this.filenameValue === "string" ? this.filenameValue : "data.png";
    link.click();
  }
}
